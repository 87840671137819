import React, { FC } from 'react';
import {  Route, Routes, useParams } from 'react-router-dom';
import PrivacyPolicies from './pages/PrivacyPolicies';
import {Navigate} from "react-router";
import RefundPrivacy from "./pages/RefundPrivacy";

interface Props {
}


const LegalInformationPage: FC<Props> = () => {
  const params = useParams();

  if (params['*']) return (
    <Routes>
      <Route path='privacy-policy' element={<PrivacyPolicies />} />
      <Route path='refund-policy' element={<RefundPrivacy />} />
      <Route path="*" element={<Navigate to="/" replace={true}/>}/>
    </Routes>
  );
  return null
};

export default LegalInformationPage;