import React, {FC} from 'react';
import LegalInformationPageWrapper from "../components/LegalInformationPageWrapper";
import LegalInfoFooter from "../components/LegalInfoFooter";

interface Props {
}

const RefundPrivacy: FC<Props> = () => {
  return (
    <LegalInformationPageWrapper title='POLICY_RETURNED'>
      <div className='text-16'>By clicking “Buy Now,” “Purchase,” or any other phrase on the purchase button, or
        entering your credit card information, or otherwise enrolling, electronically, verbally, or otherwise, you
        (“Client”) agree to be provided with products, programs, or services by the Company unless a separate Terms of
        Purchase Agreement is provided at purchase. REFUND POLICY: Refunds will be given within 60 days of joining the
        NodeUniversecourse.

      </div>
      <br/><br/><br/>
      <div className='text-16'>LIMITATION OF LIABILITY

      </div>
      <br/><br/><br/>
      <div className='text-16'>YOU AGREE THAT UNDER NO CIRCUMSTANCES SHALL WE BE LIABLE FOR DIRECT, INDIRECT,
        INCIDENTAL, CONSEQUENTIAL, SPECIAL, PUNITIVE, EXEMPLARY, OR ANY OTHER DAMAGES ARISING OUT OF YOUR USE OF THE
        SITE OR SERVICE. ADDITIONALLY, NodeUniverseIS NOT LIABLE FOR DAMAGES IN CONNECTION WITH (I) ANY FAILURE OF
        PERFORMANCE, ERROR, OMISSION, DENIAL OF SERVICE, ATTACK, INTERRUPTION, DELETION, DEFECT, DELAY IN OPERATION OR
        TRANSMISSION, COMPUTER VIRUS OR LINE OR SYSTEM FAILURE; (II) LOSS OF REVENUE, ANTICIPATED PROFITS, BUSINESS,
        SAVINGS, GOODWILL OR DATA; AND (III) THIRD PARTY THEFT OF, DESTRUCTION OF, UNAUTHORIZED ACCESS TO, ALTERATION
        OF, OR USE OF YOUR INFORMATION OR PROPERTY, REGARDLESS OF OUR NEGLIGENCE, GROSS NEGLIGENCE, FAILURE OF AN
        ESSENTIAL PURPOSE AND WHETHER SUCH LIABILITY ARISES IN NEGLIGENCE, CONTRACT, TORT, OR ANY OTHER THEORY OF LEGAL
        LIABILITY. THE FOREGOING APPLIES EVEN IF THE NodeUniverseCourse HAS BEEN ADVISED OF THE POSSIBILITY OF OR COULD
        HAVE FORESEEN THE DAMAGES. IN THOSE STATES THAT DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR THE
        DAMAGES, OUR LIABILITY IS LIMITED TO THE FULLEST POSSIBLE EXTENT PERMITTED BY LAW. IN NO EVENT SHALL
        NodeUniverseCourse CUMULATIVE LIABILITY TO YOU EXCEED $100.

      </div>
      <br/><br/><br/>
      <div className='text-16'>RELEASE OF CLAIMS

      </div>
      <br/><br/><br/>
      <div className='text-16'>In no event will the Company be liable to any party for any type of direct, indirect,
        special, incidental, or consequential damages for any use of or reliance on our Site or its Content. You hereby
        release the Company from any and all claims including those related to personal or business interruptions,
        misapplication or information, or any other loss, condition, or issue.

      </div>
      <br/><br/><br/>
      <div className='text-16'>INDEMNIFICATION</div>
      <br/><br/><br/>
      <div className='text-16'>You shall indemnify and hold us harmless from and against any and all losses, damages,
        settlements, liabilities, costs, charges, assessments and expenses, as well as third party claims and causes of
        action, including, without limitation, attorneys’ fees, arising out of any breach by you of any of these Terms
        of Service, or any use by you of the Site. You shall provide us with such assistance, without charge, as we may
        request in connection with any such defense, including, without limitation, providing us with such information,
        documents, records and reasonable access to you, as we deem necessary. You shall not settle any third party
        claim or waive any defense without our prior written consent.

      </div>
      <br/><br/><br/>
      <div className='text-16'>ONLINE COMMERCE

      </div>
      <br/><br/><br/>
      <div className='text-16'>Certain sections of the Site or its Content may allow you to make purchases from us or
        from other merchants. If you make a purchase from us on or through our Website or its Content, all information
        obtained during your purchase or transaction and all of the information that you give as part of the
        transaction, such as your name, address, method of payment, credit card number, and billing information, may be
        collected by both us, the merchant, and our payment processing company.

      </div>
      <br/><br/><br/>
      <div className='text-16'>We have no responsibility or liability for these independent policies of the payment
        processing companies and Merchants. In addition, when you make certain purchases through our Site or its
        Content, you may be subject to the additional terms and conditions of a payment processing company, Merchant or
        us that specifically apply to your purchase. For more information regarding a Merchant and its terms and
        conditions that may apply, visit that merchant’s Website and click on its information links or contact the
        Merchant directly.

      </div>
      <br/><br/>
      <div className='text-16'>Your participation, correspondence or business dealings with any affiliate, individual or
        company found on or through our Website, all purchase terms, conditions, representations or warranties
        associated with payment, refunds, and/or delivery related to your purchase, are solely between you and the
        merchant. You agree that we shall not be responsible or liable for any loss, damage, refunds, or other matters
        of any sort that incurred as the result of such dealings with a merchant.

      </div>
      <br/>
      <div className='text-16'>You release us, our affiliates, our payment processing company, and merchants from any
        damages that you incur, and agree not to assert any claims against us or them, arising from your purchase
        through or use of our Website or its Content.

      </div>
      <br/><br/><br/>
      <div className='text-16'>THIRD PARTY RESOURCES

      </div>
      <br/><br/><br/>
      <div className='text-16'>The Site may contain links to third-party websites and resources. You acknowledge and
        agree that we are not responsible or liable for the availability, accuracy, content or policies of third party
        websites or resources. Links to such websites or resources do not imply any endorsement by or affiliation with
        the Company. You acknowledge sole responsibility for and assume all risk arising from your use of any such
        websites or resources.

      </div>
      <br/>
      <div className='text-16'>If you have any questions or concerns regarding these REFUND POLICY, please email:
        support@aeunleash.pro

      </div>
      <br/><br/><br/>
      <LegalInfoFooter/>
    </LegalInformationPageWrapper>
  );
}

export default RefundPrivacy;