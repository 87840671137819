import React, {FC, useState} from 'react';
import {useTranslation} from "react-i18next";
import {ReactSVG} from "react-svg";
import mail_svg from '../../../assets/icons/mail.svg';
import lock_svg from '../../../assets/icons/lock.svg';
import shield_svg from '../../../assets/icons/shield.svg';
import axios from "axios";
import {backendUrl} from "../../../modules/api";

interface Props {
}

const HomeForm: FC<Props> = () => {
  const {t} = useTranslation();

  const [loading, setLoading] = useState(false);
  const [card, setCard] = useState({first: '', last: ''});
  const [status, setStatus] = useState<'success'|'failed'|null>(null);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (loading) return;
    setLoading(true);
    setStatus(null);
    try {
      const res = await axios.post(backendUrl + '/unsubscribe', {query: `${card.first}*${card.last}`});
      if (res.data?.result) {
        setStatus('success')
      } else {
        setStatus('failed');
      }
    } catch (e) {
      setStatus('failed');
    } finally {
      setLoading(false);
    }
  }

  const handleChangeCard = (key: string, max: number) => (e: any) => {
    const value = e.target.value;
    setCard((prevState: any) => {
      return {...prevState, [key]: value.length > max ? prevState[key] : value}
    })
  }

  return (
    <section>
      <form onSubmit={handleSubmit}>
        <div className="home-form">
          <h4 className='mb-4'>{t('SET_CARD')}</h4>
          <div className="home-form-container">
            <div className='home-form-card'>
              <div className='d-flex align-items-end'>
                <div className='form-group'>
                  <label className='form-label'>{t('CARD_NUMBER_LABEL_1')}</label>
                  <input
                    value={card.first}
                    onChange={handleChangeCard('first', 6)}
                    name='card-first' type="number" className='form-control' placeholder={'123456'} required/>
                </div>
                <div className='text-card home-form-card-num'>** ****</div>
                <div className='form-group'>
                  <label className='form-label'>{t('CARD_NUMBER_LABEL_2')}</label>
                  <input
                    value={card.last}
                    onChange={handleChangeCard('last', 4)}
                    name='card-last' type="number" className='form-control' placeholder={'5432'} required/>
                </div>
              </div>
              <div className="home-form-card-holder">CARDHOLDER</div>

            </div>
            <div className='home-form-security'>
              <ReactSVG src={lock_svg} className='react-icon'/>
              <p>{t('HOME_FORM_SECURITY_1')}</p>
            </div>
            <div className='home-form-security'>
              <ReactSVG src={shield_svg} className='react-icon'/>
              <p>{t('HOME_FORM_SECURITY_2')}</p>
            </div>
            <div className='position-relative'>
              <button className={`btn btn-primary w-100 mt-4${loading ? ' btn-loading' : ''}`} type='submit'>
                {t('UNSUB')}
              </button>
              {status
                ?
                <div className='home-form-payment-status'>
                  {status === 'failed' && <span className='text-danger'>{t('PAYMENT_FAILED')}</span>}
                  {status === 'success' && <span className='text-success'>{t('PAYMENT_SUCCESS')}</span>}
                </div>
                :
                null
              }
            </div>
          </div>
        </div>
      </form>
    </section>
  );
}

export default HomeForm;