import React, {FC} from 'react';
import {useTranslation} from "react-i18next";
import pci_security from '../assets/images/pci-security.png';
import visa_security from '../assets/images/visa-security.png';
import master_security from '../assets/images/master-security.png';
import visa  from '../../../assets/images/visa.svg';
import master  from '../../../assets/images/master.svg';
import {ReactSVG} from "react-svg";
import {Link} from "react-router-dom";

interface Props {
}

const HomeFooter: FC<Props> = () => {
  const {t} = useTranslation();

  return (
      <footer className='home-footer'>
        <div className='text-16 text-muted'>{t('PAYMENT_PROTECTED')}</div>
        <div className='home-footer-partners'>
          <img src={pci_security} alt="pci"/>
          <div className="home-footer-partners_separator" />
          <img src={master_security} alt="master"/>
          <div className="home-footer-partners_separator" />
          <img src={visa_security} alt="visa"/>
        </div>
        <div className="home-footer-links">
          <Link to={'/legacy/privacy-policy'} target='_blank'>{t('POLICY')}</Link>
          <Link to={'/legacy/refund-policy'} target='_blank'>{t('POLICY_RETURNED')}</Link>
          {/*<a href="#" target='_blank'>{t('REMOVE_SUB')}</a>*/}
          {/*<a href="#" target='_blank'>{t('OFFER')}</a>*/}
        </div>
        <div className="home-footer-bottom">
          <div className="container d-flex justify-content-between align-items-center">
            <div className='text-16 text-muted pe-2'>{t('PAYMENT_ACCEPT')}</div>
            <div className='d-flex gap-2'>
              <ReactSVG src={visa} className='react-icon home-footer-bottom-icon' />
              <ReactSVG src={master} className='react-icon home-footer-bottom-icon' />
            </div>
          </div>
        </div>
      </footer>
  );
}

export default HomeFooter;