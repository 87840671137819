import React, {useEffect, useState} from 'react';
import HomePage from "./pages/HomePage";
import {Navigate, Route, Routes} from "react-router";
import LegalInformationPage from "./pages/LegalInformationPage";

function App() {

  useEffect(() => {

  }, []);



  return (
    <Routes>
      <Route path="/" element={<HomePage/>}/>
      <Route path="/legacy/*" element={<LegalInformationPage/>}/>
      <Route path="*" element={<Navigate to="/" replace={true}/>}/>
    </Routes>
  );
}

export default App;
