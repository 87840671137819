import React, {FC} from 'react';
import {useTranslation} from "react-i18next";
import sub_img from '../assets/images/sub.png';
import card_img from '../assets/images/card.png';
import {getLanguageName} from "../../../modules/utils";
import {ELanguage} from "../../../i18n";

interface Props {
}

const HomeInfo: FC<Props> = () => {
  const {t, i18n} = useTranslation();

  const handleChangeLang = (e: any) => {
    const ln = e.target.value;
    localStorage.setItem('ln', ln)
    i18n.changeLanguage(ln);
  }

  return (
    <div className='container'>
      <h1>{t('SUB_MANAGE')}</h1>
      <div className='d-flex justify-content-center mt-3'>
        <div className='form-group'>

          <select value={i18n.language} onChange={handleChangeLang} className='form-control'>
            {Object.values(ELanguage).map(ln => (
              <option value={ln} key={ln}>{getLanguageName(ln)}</option>
            ))}
          </select>
        </div>
      </div>
      <section>
        <div className='home-card'>
          <div className='flex-1 pe-4'>
            <h3>{t('HOME_ITEM_1_TITLE')}</h3>
            <p style={{maxWidth: 490}}>{t('HOME_ITEM_1_TEXT')}</p>
          </div>
          <div className='home-card-image'>
            <div className="home-sub">
              <img src={sub_img} alt="sub"/>
            </div>
          </div>
        </div>
        <div className='home-card'>
          <div className='home-card-image'>
            <div className="home-cardholder">
              <img src={card_img} alt="sub"/>
            </div>
          </div>
          <div className='flex-1'>
            <h3>{t('HOME_ITEM_2_TITLE')}</h3>
            <p style={{maxWidth: 560}}>{t('HOME_ITEM_2_TEXT')}</p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default HomeInfo;