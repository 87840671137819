import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from "react-router-dom";
import {ReactSVG} from "react-svg";
import arrow_left from '../../../assets/icons/arrow_left.svg';
//@ts-ignore
import {Helmet} from "react-helmet";

interface Props {
  children: any;
  title: string;
  date?: string;
}

const LegalInformationPageWrapper: FC<Props> = ({title, date, children}) => {
  const {t} = useTranslation();
  return (
    <>
      <Helmet>
        <title>Unsu.me - {t(title)}</title>
      </Helmet>

    <main className='container d-flex flex-column' style={{overflowX: 'hidden'}}>
      <div className='d-flex mb-4 align-items-center'>
        <Link to={'/'}><ReactSVG src={arrow_left} className='react-icon' /></Link>
        <h1 className='flex-1 pe-4 ps-4'>{t(title)}</h1>
      </div>
      {/*<h3 className='legal-information-date'>{t('REVISION_DATE')}: {date || ''}</h3>*/}
      <div className='legal-information-content'>
        {children}
      </div>
    </main>
    </>
  );
};

export default LegalInformationPageWrapper;