import React, {FC} from 'react';
import LegalInformationPageWrapper from "../components/LegalInformationPageWrapper";
import LegalInfoFooter from "../components/LegalInfoFooter";

interface Props {
}

const PrivacyPolicies: FC<Props> = () => {
  return (
    <LegalInformationPageWrapper title='POLICY'>
      <p>Thank you for choosing UnleashAE. We at UnleashAE (“UnleashAE“, “we”, “us”) value your UnleashAE and aim to
        make clear how we gather, utilize, and disseminate data about you. This UnleashAE Policy outlines our data
        collection methods and explains your rights concerning your personal data.

        Unless we direct you to a different policy or indicate otherwise, this UnleashAE Policy is applicable when you
        visit or utilize UnleashAE websites, mobile applications, APIs, or associated services (the “Services”). It also
        applies to potential customers of our business and enterprise products.

        By using the Services, you consent to the terms of this UnleashAE Policy. If you disagree with this UnleashAE
        Policy or any other agreement that governs your use of the Services, you should refrain from using the Services.
      </p>
      <h3>Table of Contents
      </h3>
      <ul>
        <li><a href="#privacy_point_1">1. What Data We Get</a></li>
        <li><a href="#privacy_point_2">2. How We Get Data About You</a></li>
        <li><a href="#privacy_point_3">3. What We Use Your Data For</a></li>
        <li><a href="#privacy_point_4">4. Who We Share Your Data With</a></li>
        <li><a href="#privacy_point_5">5. Security</a></li>
        <li><a href="#privacy_point_6">6. Your Rights</a></li>
        <li><a href="#privacy_point_7">7. Jurisdiction-Specific Rules</a></li>
        <li><a href="#privacy_point_8">8. Updates & Contact Info</a></li>
      </ul>
      <h3 id='privacy_point_1'>1. The Information We Collect</h3>
      <p>We gather certain data directly from you, such as information you input yourself, data related to your content
        consumption, and data from third-party platforms you link with UnleashAE. We also automatically collect some
        data, like information about your device and which parts of our Services you engage with or spend time using.
        All data mentioned in this section is subject to the following processing activities: collection, recording,
        structuring, storage, modification, retrieval, encryption, pseudonymization, erasure, combination, and
        transmission.</p>
      <h4>1.1 Information You Share with Us</h4>
      <p>We may gather various data from or about you depending on how you utilize the Services. Here are some examples
        to help you better understand the data we collect.</p>
      <p>When you create an account and use the Services, including through a third-party platform, we collect any data
        you provide directly, including:

      </p>
      <div className="table-wrap">
        <table>
          <tbody>
          <tr>
            <th>Category of Personal Data</th>
            <th>Description</th>
            <th>Legal Basis for Processing</th>
          </tr>
          <tr>
            <td>Account Data

            </td>
            <td>To utilize certain features (such as accessing content), you need to create a user account. This
              requires us to collect and store your email address, password, and account settings. To establish an
              instructor account, we collect and store your name, email address, password, and account settings. As you
              engage with certain features on the site, you may be asked to provide additional information including
              your occupation, government ID information, verification photo, date of birth, race/ethnicity, areas of
              interest, and phone number. Upon account creation, we assign you a unique identification number.

            </td>
            <td>
              <ul>
                <li>Performance of contract
                </li>
                <li>Legitimate interests (service provisioning, identity verification, fraud prevention and security,
                  communication)
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Profile Data

            </td>
            <td>You also have the option to provide profile information such as a photo, headline, biography, language,
              website link, social media profiles, country, or other data. Your Profile Data will be publicly visible to
              others.

            </td>
            <td>
              <ul>
                <li>Performance of contract
                </li>
                <li>Legitimate interests (enhanced platform functionality, convey content source information)
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Shared Content

            </td>
            <td>Certain aspects of the Services allow you to interact with other users or share content publicly,
              including by uploading courses and other educational content, posting reviews about content, asking or
              answering questions, sending messages to students or instructors, or posting photos or other work you
              upload. Depending on where it is posted, such shared content may be publicly viewable by others.

            </td>
            <td>
              <ul>
                <li>Performance of contract
                </li>
                <li>Legitimate interests (service provisioning, enhanced platform functionality)
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Learning Data

            </td>
            <td>When you access content, we collect specific data including which courses, assignments, labs,
              workspaces, and quizzes you’ve started and completed; content and subscription purchases and credits;
              subscriptions; completion certificates; your interactions with instructors, teaching assistants, and other
              students; and essays, answers to questions, and other items submitted to meet course and related content
              requirements.

            </td>
            <td>
              <ul>
                <li>Performance of contract
                </li>
                <li>Legitimate interests (service provisioning, enhanced platform functionality)
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Student Payment Data

            </td>
            <td>If you make purchases, we collect certain data about your purchase (such as your name, billing address,
              and ZIP code) as necessary to process your order, which may optionally be saved for processing future
              orders. You must provide certain payment and billing data directly to our payment service providers,
              including your name, credit card information, billing address, and ZIP code. We may also receive limited
              information, like the fact that you have a new card and the last four digits of that card, from payment
              service providers to facilitate payments. For security, UnleashAE does not collect or store sensitive
              cardholder data, such as full credit card numbers or card authentication data.

            </td>
            <td>
              <ul>
                <li>Performance of contract
                </li>
                <li>Legal obligation
                </li>
                <li>Legitimate interests (payment facilitation, fraud prevention and security, compliance)
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Data About Your Accounts on Other Services

            </td>
            <td>
              We may acquire certain information through your social media or other online accounts if they are
              connected to your UnleashAE account. If you log in to UnleashAE via Facebook or another third-party
              platform or service, we ask for your permission to access certain information about that other account.
              For instance, depending on the platform or service we may collect your name, profile picture, account ID
              number, login email address, location, physical location of your access devices, gender, birthday, and
              list of friends or contacts.
              <br/>
              <br/>
              These platforms and services provide information to us through their APIs. The information we receive
              depends on what information you (via your privacy settings) or the platform or service decide to give us.
              <br/>
              <br/>
              If you access or use our Services through a third-party platform or service, or click on any third-party
              links, the collection, use, and sharing of your data will also be subject to the privacy policies and
              other agreements of that third party.
            </td>
            <td>
              <ul>
                <li>Legitimate interests (identity verification, user experience improvement)
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Sweepstakes, Promotions, and Surveys

            </td>
            <td>We may invite you to complete a survey or participate in a promotion (like a contest, sweepstakes, or
              challenge), either through the Services or a third-party platform. If you participate, we will collect and
              store the data you provide as part of participating, such as your name, email address, postal address,
              date of birth, or phone number. That data is subject to this Privacy Policy unless otherwise stated in the
              official rules of the promotion or in another privacy policy. The data collected will be used to
              administer the promotion or survey, including for notifying winners and distributing rewards. To receive a
              reward, you may be required to allow us to post some of your information publicly (like on a winner’s
              page). Where we use a third-party platform to administer a survey or promotion, the third party’s privacy
              policy will apply.

            </td>
            <td>
              <ul>
                <li>Performance of contract
                </li>
                <li>Legitimate interests (promotions administration, prize delivery, compliance)
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Communications and Support

            </td>
            <td>If you contact us for support or to report a problem or concern (regardless of whether you have created
              an account), we collect and store your contact information, messages, and other data about you like your
              name, email address, messages, location, UnleashAE user ID, refund transaction IDs, and any other data you
              provide or that we collect through automated means (which we cover below). We use this data to respond to
              you and research your question or concern, in accordance with this Privacy Policy.

            </td>
            <td>
              <ul>
                <li>Legitimate interests (customer and technical support)
                </li>
              </ul>
            </td>
          </tr>
          </tbody>

        </table>
      </div>
      <p>The data listed above is stored by us and associated with your account.

      </p>
      <h4>1.2 Data We Collect through Automated Means
      </h4>
      <p>When you access the Services (including browsing content), we collect certain data by automated means,
        including:

      </p>
      <div className="table-wrap">
        <table>
          <tbody>
          <tr>
            <th>Category of Personal Data

            </th>
            <th>Description</th>
            <th>
              Legal Basis for Processing
            </th>
          </tr>
          <tr>
            <td>System Data

            </td>
            <td>Technical data about your computer or device, like your IP address, device type, operating system type
              and version, unique device identifiers, browser, browser language, domain and other systems data, and
              platform types.

            </td>
            <td>
              <ul>
                <li>Performance of contract
                </li>
                <li>Legitimate interests (service provisioning, customer and technical support, fraud prevention and
                  security, communication, product improvement)
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Usage Data

            </td>
            <td>Usage statistics about your interactions with the Services, including content accessed, time spent on
              pages or the Service, pages visited, features used, your search queries, click data, date and time,
              referrer, and other data regarding your use of the Services.

            </td>
            <td>
              <ul>
                <li>Legitimate interests (service provisioning, user experience improvement, product improvement)
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Approximate Geographic Data

            </td>
            <td>An approximate geographic location, including information like country, city, and geographic
              coordinates, calculated based on your IP address.

            </td>
            <td>
              <ul>
                <li>Legitimate interests (user experience improvement, fraud prevention and security, compliance)
                </li>
              </ul>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <p>The data listed above is collected through the use of server log files and tracking technologies, as detailed
        in the “Cookies and Data Collection Tools” section below. It is stored by us and associated with your account.

      </p>
      <h4>1.3 Data From Third Parties
      </h4>
      <p>If you are a UnleashAE Business enterprise or corporate prospect, in addition to information you submit to
        us, we may collect certain business contact information from third-party commercial sources.

      </p>
      <h3 id={'privacy_point_2'}>2. How We Get Data About You
      </h3>
      We use tools like cookies, web beacons, and similar tracking technologies to gather the data listed above. Some
      of these tools offer you the ability to opt out of data collection.
      <h4>2.1 Cookies and Data Collection Tools
      </h4>
      <p>
        We use cookies, which are small text files stored by your browser, to collect, store, and share data about
        your activities across websites, including on UnleashAE. They allow us to remember things about your visits to
        UnleashAE, like your preferred language, and to make the site easier to use. To learn more about cookies,
        visit https://cookiepedia.co.uk/all-about-cookies. We may also use clear pixels in emails to track
        deliverability and open rates.

        <br/>
        <br/>
        UnleashAE and service providers acting on our behalf (like Google Analytics and third-party advertisers) use
        server log files and automated data collection tools like cookies, tags, scripts, customized links, device or
        browser fingerprints, and web beacons (together, “Data Collection Tools“) when you access and use the
        Services. These Data Collection Tools automatically track and collect certain System Data and Usage Data (as
        detailed in Section 1) when you use the Services. In some cases, we tie data gathered through those Data
        Collection Tools to other data that we collect as described in this Privacy Policy.


      </p>
      <h4>2.2 Why We Use Data Collection Tools
      </h4>
      <p>UnleashAE uses the following types of Data Collection Tools for the purposes described:

      </p>

      <ul>
        <li>Strictly Necessary: These Data Collection Tools enable you to access the site, provide basic functionality
          (like logging in or accessing content), secure the site, protect against fraudulent logins, and detect and
          prevent abuse or unauthorized use of your account. These are required for the Services to work properly, so
          if you disable them, parts of the site will break or be unavailable.
        </li>
        <li>Functional: These Data Collection Tools remember data about your browser and your preferences, provide
          additional site functionality, customize content to be more relevant to you, and remember settings affecting
          the appearance and behavior of the Services (like your preferred language or volume level for video
          playback).
        </li>
        <li>Performance: These Data Collection Tools help measure and improve the Services by providing usage and
          performance data, visit counts, traffic sources, or where an application was downloaded from. These tools
          can help us test different versions of UnleashAE to see which features or content users prefer and determine
          which email messages are opened.
        </li>
        <li>Advertising: These Data Collection Tools are used to deliver relevant ads (on the site and/or other sites)
          based on things we know about you like your Usage and System Data (as detailed in Section 1), and things
          that the ad service providers know about you based on their tracking data. The ads can be based on your
          recent activity or activity over time and across other sites and services. To help deliver tailored
          advertising, we may provide these service providers with a hashed, anonymized version of your email address
          (in a non-human-readable form) and content that you share publicly on the Services.
        </li>
        <li>Social Media: These Data Collection Tools enable social media functionality, like sharing content with
          friends and networks. These cookies may track a user or device across other sites and build a profile of
          user interests for targeted advertising purposes.
        </li>
      </ul>
      <p>You can set your web browser to alert you about attempts to place cookies on your computer, limit the types
        of cookies you allow, or refuse cookies altogether. If you do, you may not be able to use some or all features
        of the Services, and your experience may be different or less functional. To learn more about managing Data
        Collection Tools, refer to Section 6.1 (Your Choices About the Use of Your Data) below.

      </p>
      <h3 id={'privacy_point_3'}>3. How We Utilize Your Data</h3>
      <p>We use your data for purposes such as providing our Services, communicating with you, resolving issues,
        safeguarding against fraud and abuse, enhancing and updating our Services, analyzing the usage of our
        Services, delivering personalized advertising, and as mandated by law or necessary for safety and integrity.
        We retain your data for as long as necessary to fulfill the purposes for which it was collected.</p>
      <p>We use the data we gather through your use of the Services to:

      </p>
      <ul>
        <li>Deliver and manage the Services, including facilitating participation in educational content, issuing
          completion certificates, displaying customized content, and facilitating communication with other users
          (Account Data; Shared Content; Learning Data; System Data; Usage Data; Approximate Geographic Data);
        </li>
        <li>Process payments to instructors and other third parties (Student Payment Data; Instructor Payment Data);
        </li>
        <li>Process your requests and orders for educational content, products, specific services, information, or
          features (Account Data; Learning Data; Student Payment Data; System Data; Communications and Support);
        </li>
        <li>Communicate with you about your account by (Account Data; Shared Content; Learning Data; Sweepstakes,
          Promotions, and Surveys; System Data; Communications and Support):
        </li>
        <li>Responding to your questions and concerns;
        </li>
        <li>Sending you administrative messages and information, including messages from instructors, students, and
          teaching assistants; notifications about changes to our Service; and updates to our agreements;
        </li>
        <li>Sending you information, such as by email or text messages, about your progress in courses and related
          content, rewards programs, new services, new features, promotions, newsletters, and other available
          instructor-created content (which you can opt out of at any time);
        </li>
        <li>Sending push notifications to your wireless device to provide updates and other relevant messages (which you
          can manage from the “options” or “settings” page of the mobile app).
        </li>
        <li>Manage your account and account preferences and personalize your experience (Account Data; Learning Data;
          Student Payment Data; Instructor Payment Data; System Data, Usage Data, Cookie Data);
        </li>
        <li>Facilitate the Services’ technical functioning, including troubleshooting and resolving issues, securing the
          Services, and preventing fraud and abuse (Account Data; Student Payment Data; Instructor Payment Data;
          Communications and Support; System Data; Approximate Geographic Location);
        </li>
        <li>Verify the identity of instructors (Account Data; Instructor Payment Data);
        </li>
        <li>Solicit feedback from users (Account Data; Communications and Support);
        </li>
        <li>Market products, services, surveys, and promotions (Account Data; Learning Data; Sweepstakes, Promotions,
          and Surveys; Usage Data; Cookie Data);
        </li>
        <li>Market Subscription Plans to prospective customers (Account Data; Learning Data; Cookie Data);
        </li>
        <li>Learn more about you by linking your data with additional data through third-party data providers and/or
          analyzing the data with the help of analytics service providers (Account Data; Data About Your Accounts on
          Other Services; Usage Data; Cookie Data);
        </li>
        <li>Identify unique users across devices (Account Data; System Data; Cookie Data);
        </li>
        <li>Tailor advertisements across devices (Cookie Data);
        </li>
        <li>Improve our Services and develop new products, services, and features (all data categories);
        </li>
        <li>Analyze trends and traffic, track purchases, and track usage data (Account Data; Learning Data; Student
          Payment Data; Communications and Support; System Data; Usage Data; Approximate Geographic Data; Cookie Data);
        </li>
        <li>Advertise the Services on third-party websites and applications (Account Data; Cookie Data);
        </li>
        <li>As required or permitted by law (all data categories); or
        </li>
        <li>As we, in our sole discretion, otherwise determine to be necessary to ensure the safety or integrity of our
          users, employees, third parties, the public, or our Services (all data categories).
        </li>
      </ul>
      <h3 id={'privacy_point_4'}>4. Who We Share Your Data With
      </h3>
      <p>We share certain data about you with instructors, other students, companies providing services for us,
        UnleashAE affiliates, our business partners, analytics and data enrichment providers, your social media
        providers, companies assisting us in running promotions and surveys, and advertising companies who help us
        promote our Services. We may also share your data as needed for security, legal compliance, or as part of a
        corporate restructuring. Furthermore, we can share data in other ways if it is aggregated or de-identified or if
        we obtain your consent.

      </p>
      <p>We may share your data with third parties under the following circumstances or as otherwise described in this
        Privacy Policy:

      </p>
      <ul>
        <li>With Your Instructors: We share data that we have about you (except your email address) with instructors or
          teaching assistants for courses you access or request information about, so they can improve their courses for
          you and other students. This data may include things like your country, browser language, operating system,
          device settings, the site that led you to UnleashAE, and certain activities on UnleashAE, like enrolled
          courses and course reviews. We will not share your email address with instructors or teaching assistants.
          (Account Data; System Data; Usage Data; Approximate Geographic Data)
        </li>
        <li>With Other Students and Instructors: Depending on your settings, your shared content and profile data may be
          publicly viewable, including to other students and instructors. If you ask a question to an instructor or
          teaching assistant, your information (including your name) may also be publicly viewable. (Account Data;
          Profile Data; Shared Content)
        </li>
        <li>With Service Providers, Contractors, and Agents: We share your data with third-party companies who perform
          services on our behalf, like payment processing, fraud and abuse prevention, data analysis, marketing and
          advertising services (including retargeted advertising), email and hosting services, and customer services and
          support. These service providers may access your personal data and are required to use it solely as we direct,
          to provide our requested service. (All data categories)
        </li>
        <li>With UnleashAE Affiliates: We may share your data within our corporate family of companies that are related
          by common ownership or control to enable or support us in providing the Services. (All data categories)
        </li>
        <li>With Business Partners: We have agreements with other websites and platforms to distribute our Services and
          drive traffic to UnleashAE. Depending on your location, we may share your data with these trusted partners.
          (Account Data; Learning Data; Communications and Support; System Data)
        </li>
        <li>With Credit-Granting Organizations for Continuing Education: If you take a course to fulfill a continuing
          professional education requirement, we may share that information upon request of the organization granting
          the continuing education credit. (Account Data; Learning Data)
        </li>
        <li>With Analytics and Data Enrichment Services: As part of our use of third-party analytics tools like Google
          Analytics and data enrichment services like ZoomInfo, we share certain contact information or de-identified
          data. De-identified data means data where we’ve removed things like your name and email address and replaced
          it with a token ID. This allows these providers to provide analytics services or match your data with
          publicly-available database information (including contact and social information from other sources). We do
          this to communicate with you in a more effective and customized manner. (Account Data; System Data; Usage
          Data; Cookie Data)
        </li>
        <li>To Power Social Media Features: The social media features in the Services (like the Facebook Like button)
          may allow the third-party social media provider to collect things like your IP address and which page of the
          Services you’re visiting, and to set a cookie to enable the feature. Your interactions with these features are
          governed by the third-party company’s privacy policy. (System Data; Usage Data; Cookie Data)
        </li>
        <li>To Administer Promotions and Surveys: We may share your data as necessary to administer, market, or sponsor
          promotions and surveys you choose to participate in, as required by applicable law (like to provide a winners
          list or make required filings), or in accordance with the rules of the promotion or survey. (Account Data;
          Sweepstakes, Promotions, and Surveys)
        </li>
        <li>For Advertising: If we decide to use an advertising-supported revenue model in the future, we may use and
          share certain System Data and Usage Data with third-party advertisers and networks to show general demographic
          and preference information among our users. We may also allow advertisers to collect System Data through Data
          Collection Tools (as detailed in Section 2.1), to use this data to offer you targeted ad delivery to
          personalize your user experience (through behavioral advertising) and to undertake web analytics. Advertisers
          may also share with us the data they collect about you. To learn more or opt out from participating ad
          networks’ behavioral advertising, see Section 6.1 (Your Choices About the Use of Your Data) below. Note that
          if you opt out, you’ll continue to be served generic ads. (System Data)
        </li>
        <li>For Security and Legal Compliance: We may disclose your data (all data categories) to third parties if we
          (in our sole discretion) have a good faith belief that the disclosure is:
        </li>
        <li>Requested as part of a judicial, governmental, or legal inquiry, order, or proceeding;
        </li>
        <li>Reasonably necessary as part of a valid subpoena, warrant, or other legally-valid request;
        </li>
        <li>Reasonably necessary to enforce our Terms of Use, Privacy Policy, and other legal agreements;
        </li>
        <li>Required to detect, prevent, or address fraud, abuse, misuse, potential violations of law (or rule or
          regulation), or security or technical issues;
        </li>
        <li>Reasonably necessary in our discretion to protect against imminent harm to the rights, property, or safety
          of UnleashAE, our users, employees, members of the public, or our Services;
        </li>
        <li>We may also disclose data about you to our auditors and legal advisors in order to assess our disclosure
          obligations and rights under this Privacy Policy; or
        </li>
        <li>Required or permitted by law.
        </li>
        <li>During a Change in Control: If UnleashAE undergoes a business transaction like a merger, acquisition,
          corporate divestiture, or dissolution (including bankruptcy), or a sale of all or some of its assets, we may
          share, disclose, or transfer all of your data to the successor organization during such transition or in
          contemplation of a transition (including during due diligence). (All data categories)
        </li>
        <li>After Aggregation/De-identification: We may disclose or use aggregated or de-identified data for any
          purpose.
        </li>
        <li>With Your Permission: With your consent, we may share data to third parties outside the scope of this
          Privacy Policy. (All data categories)
        </li>
      </ul>
      <h3 id={'privacy_point_5'}>5. Security
      </h3>
      <p>
        We use appropriate security based on the type and sensitivity of data being stored. As with any internet-enabled
        system, there is always a risk of unauthorized access, so it’s important to protect your password and to contact
        us if you suspect any unauthorized access to your account.
        <br/>
        <br/>
        UnleashAE takes appropriate security measures to protect against unauthorized access, alteration, disclosure, or
        destruction of your personal data that we collect and store. These measures vary based on the type and
        sensitivity of the data. Unfortunately, however, no system can be 100% secured, so we cannot guarantee that
        communications between you and UnleashAE, the Services, or any information provided to us in connection with the
        data we collect through the Services will be free from unauthorized access by third parties. Your password is an
        important part of our security system, and it is your responsibility to protect it. You should not share your
        password with any third party, and if you believe your password or account has been compromised, you should
        change it immediately and contact our <a
        target='_blank'
        href="https://www.google.com/url?q=https://www.udemy.com/support/&sa=D&source=editors&ust=1721299388233738&usg=AOvVaw0HUY3LEfiBGcrQ4m70ay5N">Support
        Team</a> with any concerns.
      </p>
      <h3 id={'privacy_point_6'}>6. Your Rights
      </h3>
      <p>You have certain rights around the use of your data, including the ability to opt out of promotional emails,
        cookies, and collection of your data by certain third parties. You can update or terminate your account from
        within our Services, and can also contact us for individual rights requests about your personal data. Parents
        who believe we’ve unintentionally collected personal data about their underage child should contact us for help
        deleting that information.

      </p>
      <h4>6.1 Your Choices About the Use of Your Data</h4>
      <p>You can choose not to provide certain data to us, but you may not be able to use certain features of the
        Services.

      </p>
      <ul>
        <li>To stop receiving promotional communications from us, you can opt out by using the unsubscribe mechanism in
          the promotional communication you receive or by changing the <a
            href="https://www.google.com/url?q=https://www.udemy.com/support/229231147/&sa=D&source=editors&ust=1721299388234722&usg=AOvVaw39h26PO1dP7D7dJLsLKUse"
            target='_blank'>email preferences in your account.</a> Note that regardless of your email preference
          settings, we will send you transactional and relationship messages regarding the Services, including
          administrative confirmations, order confirmations, important updates about the Services, and notices about our
          policies.
        </li>
        <li>If you’re located in the European Economic Area, you may opt out of certain Data Collection Tools by
          clicking the “Cookie settings“ link at the bottom of any page.
        </li>
        <li>The browser or device you use may allow you to control cookies and other types of local data storage. To
          learn more about managing cookies, visit <a
            href="https://cookiepedia.co.uk/how-to-manage-cookies"
            target={'_blank'}>https://cookiepedia.co.uk/how-to-manage-cookies</a>. Your wireless device may also allow
          you to control whether location or other data is collected and shared.
        </li>
        <li>To get information and control cookies used for tailored advertising from participating companies, see the
          consumer opt-out pages for the <a
            href="https://www.google.com/url?q=http://www.networkadvertising.org/choices&sa=D&source=editors&ust=1721299388235454&usg=AOvVaw3MpM1H5i8wNgYClCRxZPY1"
            target='_blank'>Network Advertising Initiative</a> and <a
            href="https://www.google.com/url?q=http://www.aboutads.info/choices/&sa=D&source=editors&ust=1721299388235632&usg=AOvVaw0mW-So1L0Aw4xrt1jd4nVy"
            target={'_blank'}>Digital Advertising Alliance</a>, or if you’re located in the European Economic Area,
          visit the <a
            href="https://www.google.com/url?q=http://www.youronlinechoices.eu/&sa=D&source=editors&ust=1721299388235811&usg=AOvVaw3c-693_EIdsKDo2aYJ2R_M"
            target={'_blank'}>Your Online Choices</a> site. If you’re located in Japan, visit the <a
            href="https://www.google.com/url?q=https://feedback.impact-ad.jp/login&sa=D&source=editors&ust=1721299388235992&usg=AOvVaw2ZRbE0Zu7KIy6UlV1pIQz7"
            target={'_blank'}>Digital Advertising Consortium</a>. To opt out of Google’s display advertising or
          customize Google Display Network ads, visit the <a
            href="https://www.google.com/url?q=https://www.google.com/settings/ads&sa=D&source=editors&ust=1721299388236167&usg=AOvVaw2H12YqTxZG_PkDBYStNEaS"
            target={'_blank'}>Google Ads Settings page</a>. To opt out of Taboola’s targeted ads, see the Opt-out Link
          in their <a
            href="https://www.google.com/url?q=https://www.taboola.com/cookie-policy&sa=D&source=editors&ust=1721299388236359&usg=AOvVaw1zZcFd60xw6Ss433bCmxFf"
            target={'_blank'}>Cookie Policy</a>.
        </li>
        <li>To opt out of allowing Google Analytics, Mixpanel, ZoomInfo, or Clearbit to use your data for analytics or
          enrichment, see the <a
            href="https://www.google.com/url?q=https://tools.google.com/dlpage/gaoptout&sa=D&source=editors&ust=1721299388236696&usg=AOvVaw3tE7XBDvKvdXPKlx679b7Z"
            target={'_blank'}>Google Analytics Opt-out Browser Add-on</a>, <a
            href="https://www.google.com/url?q=https://mixpanel.com/optout/&sa=D&source=editors&ust=1721299388236860&usg=AOvVaw3MzO0PVoeFnIu6NeYjqtmS"
            target={'_blank'}>Mixpanel Opt-Out Cookie</a>, <a
            href="https://www.google.com/url?q=https://www.zoominfo.com/business/about-zoominfo/privacy-policy&sa=D&source=editors&ust=1721299388237079&usg=AOvVaw3sAqyX54Rc0R_W1VkD1IBN"
            target={'_blank'}>ZoomInfo’s policy</a>, and <a
            href="https://www.google.com/url?q=https://clearbit.com/privacy&sa=D&source=editors&ust=1721299388237247&usg=AOvVaw28g7EtHUN0wBP6U6T_uZfJ"
            target={'_blank'}>Clearbit data claiming mechanism</a>.
        </li>
        <li>Apple iOS, Android OS, and Microsoft Windows each provide their own instructions on how to control in-app
          tailored advertising. For other devices and operating systems, you should review your privacy settings on that
          platform.
        </li>
      </ul>
      <p>If you have any questions about your data, our use of it, or your rights, contact us at support@aeunleash.pro.

      </p>
      <h4>6.2 Accessing, Updating, and Deleting Your Personal Data
      </h4>
      <p>You can access and update your personal data that UnleashAE collects and maintains as follows:

      </p>
      <ul>
        <li>To update data you provide directly, log into your account and update your account at any time.
        </li>
        <li>To terminate your account:
        </li>
        <li>If you are a student, visit your profile settings page and follow the steps detailed <a
          href="https://www.google.com/url?q=https://www.udemy.com/support/229603628/&sa=D&source=editors&ust=1721299388238312&usg=AOvVaw3ZSsgnu-qKYGmd8C4vR1vB"
          target={'_blank'}>here</a>.
        </li>
        <li>If you are an instructor, follow the steps detailed <a
          href="https://www.google.com/url?q=https://www.udemy.com/support/229233547/&sa=D&source=editors&ust=1721299388238568&usg=AOvVaw2nwPPuTA6BKzKggCJt0Emh"
          target={'_blank'}>here</a>.
        </li>
        <li>If you have any issues terminating your account, please contact our <a
          href="https://www.google.com/url?q=https://www.udemy.com/support/&sa=D&source=editors&ust=1721299388238829&usg=AOvVaw23kxjgOBJxeLZ1wSbzEvJL"
          target='_blank'>Support Team</a>.
        </li>
        <li>Please note: even after your account is terminated, some or all of your data may still be visible to others,
          including without limitation any data that has been (a) copied, stored, or disseminated by other users
          (including comments on content); (b) shared or disseminated by you or others (including in your shared
          content); or (c) posted to a third-party platform. Even after your account is terminated, we retain your data
          for as long as we have a legitimate purpose to do so (and in accordance with applicable law), including to
          assist with legal obligations, resolve disputes, and enforce our agreements. We may retain and disclose such
          data pursuant to this Privacy Policy after your account has been terminated.
        </li>
        <li>To request to access, correct, or delete your personal data, please use our online form <a
          href="https://www.google.com/url?q=https://support.udemy.com/hc/requests/new?ticket_form_id%3D360002416854&sa=D&source=editors&ust=1721299388239222&usg=AOvVaw2dY34m44KJWEpBTBOMTrmy"
          target={'_blank'}>here</a>. You can also submit these requests by emailing <a
          href="mailto:support@aeunleash.pro" target={'_blank'}>support@aeunleash.pro</a>. For your protection, we may
          require that the request be sent through the email address associated with your account, and we may need to
          verify your identity before implementing your request. Please note that we retain certain data where we have a
          lawful basis to do so, including for mandatory record-keeping and to complete transactions.
        </li>
      </ul>
      <h4>6.3 Our Policy Concerning Children
      </h4>
      <p>We recognize the privacy interests of children and encourage parents and guardians to take an active role in
        their children’s online activities and interests. Individuals younger than 18 years of age, but of the required
        age for consent to use online services where they live (for example, 13 in the US or 16 in Ireland), may not set
        up an account, but may have a parent or guardian open an account and help them access appropriate content.
        Individuals younger than the required age for consent to use online services may not use the Services. If we
        learn that we’ve collected personal data from a child under those ages, we will take reasonable steps to delete
        it.

      </p>
      <p>Parents who believe that UnleashAE may have collected personal data from a child under those ages can submit a
        request that it be removed to <a
          href="mailto:support@aeunleash.pro" target={'_blank'}>support@aeunleash.pro</a>.

      </p>
      <h3 id={'privacy_point_7'}>7. Jurisdiction-Specific Rules
      </h3>
      <p>If you live in California, you have certain rights related to accessing and deleting your data, as well as
        learning who we share your data with. If you live in Australia, you have the right to make a formal complaint
        with the appropriate government agency. Users outside of the United States should note that we transfer data to
        the US and other areas outside of the European Economic Area.

      </p>
      <h4>7.1 Users in California
      </h4>
      <p>Users who are California residents have certain rights under the California Consumer Privacy Act, (“CCPA”). If
        you are an eligible California user, included in these rights are:

      </p>
      <ul>
        <li>“Right to Know” — You have the right to request to know more about the categories and specific pieces of
          personal information that we have collected about you and access a copy of your personal information.
        </li>
        <li>“Right to Correction” — You have the right to have inaccurate personal information about you corrected.
        </li>
        <li>“Right to Deletion” — You have the right to request deletion of personal information that we have collected
          about you.
        </li>
        <li>“Right to Non-Discrimination” — If you choose to exercise any of your rights under CCPA, UnleashAE will
          treat you like all other users. In other words, there is no penalty for exercising your rights under CCPA.
        </li>
        <li>“Right to Opt-Out” – You have the right to opt out of the sale of your personal information.
        </li>
      </ul>
      <p>CCPA has a specific definition of a “sale” and while UnleashAE does not, in the traditional sense, sell your
        personal information or the personal information of any of our users, we do use cookies that make non-personally
        identifiable information available to select third-parties. To opt out of such a “sale,” click on the “Do Not
        Sell My Personal Information” link at the bottom of this page.

      </p>
      <p>To exercise any of these rights under CCPA, please email support@aeunleash.pro. CCPA allows you to designate an
        authorized agent to make these requests on your behalf. For your protection, we may require that the request be
        sent through the email address associated with your account, and we may need to verify you and/or your agent’s
        identity before fulfilling your request.

      </p>
      <p>Additionally, for more information about the personal information we collect and how we collect it, please see
        the sections above entitled “What Data We Get” and “How We Get Data About You.”

      </p>
      <p>To learn about the business and commercial purposes for which your personal information is collected and the
        categories of service providers who have access to your personal information, please see the sections above
        entitled “What We Use Your Data For” and “Who We Share Your Data With.”

      </p>
      <p>As a California resident, you also have the right to request certain details about what personal information we
        share with third parties for those third parties’ direct marketing purposes. To submit your request, send an
        email to support@aeunleash.pro with the phrase “California Shine the Light” and include your mailing address,
        state of residence, and email address.

      </p>
      <p>Since there is no widely accepted standard for the browser-initiated Do Not Track signal, we do not currently
        recognize or respond to Do Not Track signals.

      </p>
      <h4>7.2 Users in Nevada
      </h4>
      <p>UnleashAE does not sell its users’ personal information or personal data. Nonetheless, Nevada residents have
        the right to submit a request that we do not sell your covered personal information, which you can do by
        emailing support@aeunleash.pro

      </p>
      <h4>7.3 Users in Australia
      </h4>
      <p>If you are an Australia resident and you have a complaint, you may refer it to the office of the Australian
        Information Commissioner (“OAIC”). You can contact OAIC by visiting <a
          href="www.oaic.gov.au" target={'_blank'}>www.oaic.gov.au</a>; forwarding an email to enquiries@oaic.gov.au;
        telephoning 1300 363 992; or writing to OAIC at GPO Box 5218, Sydney NSW 2001. You may contact our privacy team
        at support@aeunleash.pro to make a complaint about a breach of the Australian Privacy Principles which will be
        responded to within 30 days.

      </p>
      <h4>7.4 Users in the European Economic Area (“EEA”) and United Kingdom (“UK”)
      </h4>
      <p>If you are located in the EEA or UK, you have the right to request access to your data in a portable format and
        to request the rectification, erasure, restriction of processing, or objection to processing of your personal
        data. You also have the right to obtain a free copy of the Standard Contractual Clauses referenced in Section
        7.5. You may use the information in Section 6.2 to submit your request. Additionally, if you are located in the
        EEA, UK, or Switzerland, you also have the right to lodge a complaint with your supervisory authority.

      </p>
      <p>Personal data is also processed outside of the UK, Switzerland, and the EEA by our UnleashAE group companies,
        or our service providers, including to process transactions, facilitate payments, and provide support services
        as described in Section 4. We use the Controller-to-Processor Standard Contractual Clauses adopted by the
        European Commission to facilitate transfers of personal data from the EEA to third countries and have entered
        into data processing agreements with our service providers and UnleashAE group companies to restrict and
        regulate their processing of your data. By submitting your data or using our Services, you consent to this
        transfer, storage, and processing by UnleashAE and its processors.

      </p>
      <h4>7.5 Users Outside of the U.S.
      </h4>
      <p>In order to provide the Services to you, we must transfer your data to the United States and process it there.
        If you are using the Services from outside the United States, you consent to the transfer, storage, and
        processing of your data in and to the United States or other countries.

      </p>
      <h3 id={'privacy_point_8'}>8. Updates & Contact Info
      </h3>
      <p>When we make a material change to this policy, we’ll notify users via email, in-product notice, or another
        mechanism required by law. Changes become effective the day they’re posted. Please contact us via email or
        postal mail with any questions, concerns, or disputes.

      </p>
      <h4>8.1 Modifications to This Privacy Policy
      </h4>
      <p>From time to time, we may update this Privacy Policy. If we make any material change to it, we will notify you
        via email, through a notification posted on the Services, or as required by applicable law. We will also include
        a summary of the key changes. Unless stated otherwise, modifications will become effective on the day they are
        posted.

      </p>
      <p>As permitted by applicable law, if you continue to use the Services after the effective date of any change,
        then your access and/or use will be deemed an acceptance of (and agreement to follow and be bound by) the
        revised Privacy Policy. The revised Privacy Policy supersedes all previous Privacy Policies.

      </p>
      <h4>8.2 Questions
      </h4>
      <p>If you have any questions, concerns, or disputes regarding our Privacy Policy, please feel free to contact our
        privacy team (including our Data Protection Officer) at support@aeunleash.pro.

      </p>

      <LegalInfoFooter/>
    </LegalInformationPageWrapper>
  );
}

export default PrivacyPolicies;