import React, {FC, useState} from 'react';
import HomeInfo from "./components/HomeInfo";
import HomeForm from "./components/HomeForm";
import HomeFooter from "./components/HomeFooter";

interface Props {
}

const HomePage: FC<Props> = () => {

  return (
      <>
        <main className='home-page'>
          <HomeInfo />
          <HomeForm />
        </main>
        <HomeFooter />
      </>
  );
}

export default HomePage;