import React, {FC} from 'react';

interface Props {
}

const LegalInfoFooter: FC<Props> = () => {
  return (
      <footer className='legal-info-footer'>
        <h4>Contacts:</h4>
        STELLARMART LIMITED
        Company registration number: 76903430
        ROOM 511, 5/F, MING SANG IND BLDG, 19-21 HING YIP STREET, Kwun Tong Hong Kong
      </footer>
  );
}

export default LegalInfoFooter;